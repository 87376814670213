























import Vue from 'vue'

export default Vue.extend({
  middleware: ['auth'],
  data() {
    return {
      alertId: '',
      quote: '',
      srchCondition: this.$$getInitialValue('srchCondition'),
      favoriteKijiInfo: { uuid: '' },
    }
  },
  mounted() {
    // グローバルなオブジェクトにハンドラ追加
    this.$nuxt.$on('setAlertId', this.setAlertId)
    this.$nuxt.$on('setQuote', this.setQuote)
    this.$nuxt.$on('setFavoriteKijiInfo', this.setFavoriteKijiInfo)
  },
  methods: {
    /**
     * alertIdをクリア
     * @return {void}
     */
    clearAlertId(): void {
      this.alertId = ''
    },

    /**
     * アラート表示用のIDをセット
     * @params {string} alertId
     * @return {void}
     */
    setAlertId(alertId: string): void {
      console.log('setAlertId', alertId)
      this.alertId = alertId
    },

    /**
     * 引用をセット
     * @params {string} quote
     * @return {void}
     */
    setQuote(quote: string): void {
      console.log('setQuote', quote)
      this.quote = quote
    },

    /**
     * お気に入り記事情報をセット
     * @params {any} favoriteKijiInfo
     * @return {void}
     */
    setFavoriteKijiInfo(favoriteKijiInfo: any): void {
      console.log('setFavoriteKijiInfo', favoriteKijiInfo)
      this.favoriteKijiInfo = favoriteKijiInfo
    },
  },
})
